.navbar {
  position: relative;
  z-index: 10;
  width: 100%;
  @include breakpoint(sm) {
    margin-top: 20px;
  }
  @include breakpoint(lg) {
    visibility: hidden;
  }
  &:after {
    content: "";
    display: block;
    background-image: linear-gradient(-180deg, #f7941e 0%, #f15a29 100%);
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    height: 64px;
    width: 100%;
    position: relative;
    top: -100px;
    z-index: 5;
    @include breakpoint(sm) {
      top: -40px;
    }
  }
  > .mini {
    position: relative;
    left: 49px;
    top: -2px;
    // top: -7px;
    // margin-right: 20px;
    z-index: 10;
    // margin-left: 10px;
    @include breakpoint(sm) {
      display: none;
    }
  }
  > .menu {
    flex-grow: 1;
    display: flex;
    z-index: 10;
    justify-content: space-around;
    margin-left: 78px;
    margin-right: 57px;
    top: 8px;
    position: relative;
    a {
      font-family: $roboto;
      font-weight: 700;
      font-size: 19px;
      color: #ffffff;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
      &:hover {
        text-decoration: underline;
      }
      &:active {
        position: relative;
        top: 1px;
      }
      @include breakpoint(md) {
        font-size: 17px;
      }
      @include breakpoint(sm) {
        font-size: 16px;
      }
    }
  }
}
