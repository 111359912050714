.btn {
  font-family: $roboto;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: #ffffff;
  transition: all 300ms ease-in-out;
  &:hover {
    box-shadow: 0 10px 40px -10px #000;
    position: relative;
    transform: translate(0, -2px);
    transition: all 300ms ease-in-out;
  }
  &:active {
    position: relative;
    transform: translate(0, -1px);
    transition: all 0ms;
  }
  &.-primary {
    background-color: #e25825;
    color: #fff;
    padding: 10px 20px;
    margin-top: 20px;
    @include breakpoint(xs) {
      display: block;
    }
  }
}

.btn-clear {
  display: none;
}
