.navbar-container {
  z-index: 999;
  max-width: 1078px;
  position: relative;
  margin: 0 auto;
  top: 0;
  @include breakpoint(lg) {
    display: none;
  }
}
