.ticket-content {
  margin: 0 auto;
  &.-student {
    @include breakpoint(md) {
      margin-top: 30px;
    }
  }
  @include breakpoint(xs) {
    margin: 0 auto;
  }

  > .pass {
    text-align: left;
    text-align: center;
    letter-spacing: 0.1px;
    @include breakpoint(lg) {
      margin: 0;
      text-align: center;
    }
    @include breakpoint(xs) {
      font-size: 36px;
      margin-bottom: 10px;
    }
  }

  > .category {
    display: flex;
    flex-direction: row;
  }
}
