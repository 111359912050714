header {
  position: relative;
  overflow: hidden;
  // width: 100vw;
  // height: 1460px;
  background: #000;
  // .video-highlights {
  //   position: absolute;
  //   bottom: 190px;
  //   z-index: 5;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   iframe {
  //     @include breakpoint(xs) {
  //       width: 100vw;
  //       max-width: 640px;
  //     }
  //   }
  // }
  > .element-0 {
    z-index: 1;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  > .element-1 {
    position: absolute;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    top: -80px;
  }
  > .element-2 {
    position: absolute;
    z-index: 0;
    transform: translateX(-50%);
    left: 50%;
    margin-left: -400px;
  }
  > .element-3 {
    position: absolute;
    bottom: 0;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
  }
  // > .element-4 {
  //   position: absolute;
  //   bottom: -43px;
  //   z-index: 7;
  //   min-width: 1078px;
  //   left: 50%;
  //   transform: translateX(-50%);
  // }
  .element-5 {
    position: absolute;
    bottom: 0;
    z-index: 6;
    left: 50%;
    transform: translateX(-50%);
    overflow-x: hidden;
  }
  .logo {
    z-index: 5;
    position: relative;
    // left: 20px;
  }
  .robot {
    margin-top: -22px;
    position: relative;
    z-index: 5;
    @include breakpoint(md) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

/* ---- particles.js container ---- */
.particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: url("");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.sticky {
  position: relative;
}

.faz {
  overflow-x: hidden;
}
