.shirt-content {
  position: relative;
  left: -60px;
  margin-top: -57px;
  @include breakpoint(lg) {
    position: static;
    margin-top: 30px;
    left: auto;
  }
  > .title {
    font-size: 41px;
    letter-spacing: 0.7px;
    @include breakpoint(xs) {
      font-size: 36px;
    }
  }
  > .uniform {
    position: relative;
    margin-top: -13px;
    left: 3px;
  }
  > .content {
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    width: 312px;
    position: relative;
    margin-top: 6px;
    line-height: 21px;
    letter-spacing: -0.3px;
    @include breakpoint(lg) {
      text-align: center;
      margin: 0 auto;
      margin-top: 10px;
      font-size: 16px;
      line-height: 1.5;
    }
    @include breakpoint(xxs) {
      position: static;
      width: auto;
    }
  }
}
