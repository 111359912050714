.container-contact {
  margin-top: 50px;
  margin-bottom: 30px;
  button{
    padding: 10px 20px;
    @include breakpoint(sm){
      width: 100%;
      margin-top: 10px;
    }
  }
}
