.form-control {
  background-color: #352e2c !important;
  border-color: #352e2c !important;
  color: #fff;
  &:focus {
    box-shadow: none;
    color: #fff;
    border-color: #e25825 !important;
  }
}

.modal-dialog {
  @include breakpoint(xs) {
    margin: 0 auto;
    margin-top: 30px;
  }
}

.modal-lg {
  @include breakpoint(xl) {
    max-width: 1200px;
  }
  @include breakpoint(lg) {
    max-width: 800px;
  }
}
