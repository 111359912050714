.ticket-container {
  margin-top: 146px;
  display: inline-block;
  @include breakpoint(lg) {
    margin-top: 40px;
  }
  @include breakpoint(sm) {
    margin-top: 0;
  }
  > .content {
    position: relative;
    margin-top: -100px;
    margin-left: 34px;
    width: 350px;
    @include breakpoint(lg) {
      text-align: center;
      position: static;
      margin: 0 auto;
      width: 400px;
    }
    @include breakpoint(sm) {
    }
    @include breakpoint(xxs) {
      width: auto;
    }
    p {
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: 0.2px;
      color: #ffffff;
      font-weight: 500;
      @include breakpoint(lg) {
        font-size: 16px;
      }
      @include breakpoint(xxs) {
        text-align: center;
        font-size: 14px;
        span {
          display: none;
        }
      }
    }
  }
}
