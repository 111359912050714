.ticket-price {
  min-width: 220px;
  text-align: center;
  margin: 0;
  @include breakpoint(xxs) {
    min-width: auto;
    &:nth-of-type(2n) {
      margin-left: 20px;
    }
  }
  > .type {
    font-family: $roboto;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    letter-spacing: 0.2px;
    padding: 10px;
    position: relative;
    margin-right: 20px;
    margin-bottom: 0;
    margin: 0 20px;
    padding: 6px 18px;
    @include breakpoint(xxs) {
      font-size: 10px;
      margin: 0;
    }
    &.-violet {
      background-color: #3c1d38;
    }
    &.-blue {
      background-color: #16354d;
    }
    &::after {
      content: "";
      left: 0;
      top: 0;
      display: block;
      height: 100%;
      width: calc(100% + 100px);
      position: absolute;
      z-index: -1;
    }
  }
  > .price {
    font-family: $bebas-bold;
    text-transform: uppercase;
    font-size: 100px;
    color: #ffffff;
    letter-spacing: 0.2px;
    text-shadow: 4px 4px 0 #392296;
    margin-top: 14px;
    text-align: center;
    @include breakpoint(lg) {
      // margin: auto;
    }
    @include breakpoint(xs) {
      font-size: 64px;
    }
  }
}
