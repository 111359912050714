@font-face {
  font-family: "BebasNeueBold";
  src: url("../fonts/BebasNeue Bold.woff2") format("woff2"),
    url("../fonts/BebasNeue Bold.woff") format("woff"),
    url("../fonts/BebasNeue Bold.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
}

@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700");

$roboto: "Roboto", Arial, Helvetica, sans-serif;
$bebas-bold: "BebasNeueBold", Arial, Helvetica, sans-serif;
