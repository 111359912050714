footer {
  display: flex;
  padding: 30px 0;
  justify-content: center;
  flex-direction: row;
  @include breakpoint(sm) {
    text-align: center;
  }
  p {
    text-align: center;
    font-family: $roboto;
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
    letter-spacing: 0.2px;
  }
}
