.modal-content {
  //   max-width: 1200px;
  border: none;
  border-radius: 8px;
  background: none;
  .close {
    opacity: 0.7;
    color: #fff;
    align-self: flex-end;
    font-size: 42px;
    margin-bottom: 10px;
    text-align: center;
    @include breakpoint(sm) {
      margin: 15px;
      align-self: center;
      border: 1px solid #fff;
      padding: 10px 20px;
      border-radius: 30px;
      font-size: 40px;
    }
    // transition: all 100ms ease-in;
    &:hover {
      opacity: 1;
      color: #fff;
      position: relative;
      //   transform: scale(1.1);
      //   transition: all 100ms ease-in-out;
    }
  }
}
