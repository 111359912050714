/* 1. Configuration and helpers*/
@import "abstract/reset", "abstract/variables", "abstract/mixin";

/* 2. Vendors*/
@import "vendor/bootstrap.min", "vendor/fontawesome.min";

/* 3. Modules*/
@import "modules/fonts", "modules/base", "modules/helper",
  "modules/mediaqueries", "modules/modal", "modules/nav", "modules/forms",
  "modules/animation", "modules/buttons";

/* 4. Layout-related sections */
@import "layout/header", "layout/footer", "layout/navbar";

/* 5. Components*/
@import "components/_card-outlet.scss";
@import "components/_card-speaker.scss";
@import "components/_container-about.scss";
@import "components/_container-contact.scss";
@import "components/_container-navbar.scss";
@import "components/_container-speaker.scss";
@import "components/_item-sponsor.scss";
@import "components/_modal-content.scss";
@import "components/_modal-work.scss";
@import "components/_navbar-container.scss";
@import "components/_shirt-container-outlet.scss";
@import "components/_shirt-container.scss";
@import "components/_shirt-content.scss";
@import "components/_social-info.scss";
@import "components/_social-item.scss";
@import "components/_speaker-container.scss";
@import "components/_ticket-container-outer.scss";
@import "components/_ticket-container.scss";
@import "components/_ticket-content.scss";
@import "components/_ticket-panel.scss";
@import "components/_ticket-price.scss";
@import "components/_title-details.scss";
@import "components/_title-ticket.scss";
@import "components/_work-content.scss";
@import "components/_work-image.scss";

/* 6. Page-specific styles*/
@import "pages/front";

/* 7. Overwritten Vendors */
@import "vendor-extension/bootstrap";
