.ticket-panel {
  display: flex;
  flex-direction: row;
  position: relative;
  left: 14px;
  @include breakpoint(lg) {
    margin: auto;
  }
  @include breakpoint(md) {
    text-align: center;
    position: static;
    margin-top: 30px;
  }
  > .image {
    position: relative;
    margin-top: -80px;
    left: -70px;
    @include breakpoint(lg) {
      display: none;
      margin: auto;
    }
    @include breakpoint(md) {
      left: -30px;
    }
    @include breakpoint(sm) {
      display: none;
    }
  }
  &.-student {
    margin-top: -92px;
    @include breakpoint(xl) {
      margin-top: -92px;
    }
    @include breakpoint(lg) {
      margin: auto;
    }
    @include breakpoint(md) {
      margin: auto;
    }
    @include breakpoint(sm) {
      margin: auto;
    }
    @include breakpoint(xs) {
    }
  }
}
