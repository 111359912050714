.container-speaker {
  display: flex;
  justify-content: space-between;
  // flex-wrap: wrap;
  > .card-speaker {
    // margin: 6px;
    // margin-bottom: 30px;
    // margin-right: 17px;
  }
  @include breakpoint(xl) {
    > .card-speaker:nth-of-type(4n) {
      // margin-right: 0;
    }
  }
  @include breakpoint(lg) {
    > .card-speaker:nth-of-type(3n) {
      // margin-right: 0;
    }
  }
}
