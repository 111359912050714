.card-speaker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-bottom: 30px;
  transition: all 0.2s;
  &:hover {
    transform: scale(1.05);
    transition: all 0.3s;
  }
  @include breakpoint(lg) {
    .card-speaker:nth-of-type(4) {
      margin-right: 0;
    }
  }
  > .name {
    font-family: $roboto;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin-top: 8px;
  }
}
