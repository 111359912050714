.page-front {
  // overflow-x: hidden;
  .logo {
    margin-top: 45px;
    position: relative;
    left: 28px;
    @include breakpoint(sm) {
      top: auto;
      left: auto;
    }
    @include breakpoint(xs) {
      margin-top: 20px;
    }
  }
  .video-panel {
    padding: 56.25% 0 0 0;
    position: relative;
    z-index: 9;
    margin-top: 40px;
    margin-bottom: 20px;
    @include breakpoint(lg) {
      margin-bottom: 60px;
    }
    @include breakpoint(md) {
      margin-bottom: 40px;
    }
  }

  .section-speakers {
    position: relative;
    z-index: 6;
    background: #0a0720;
    padding-top: 0;
    padding-bottom: 0;
    // overflow-x: hidden;
    overflow: hidden;
    @include breakpoint(lg) {
      padding-top: 80px;
    }
    .title {
      margin-top: -60px;
      margin-bottom: 40px;
      @include breakpoint(lg) {
        margin-top: -80px;
      }
      @include breakpoint(sm) {
        font-size: 36px;
      }
      @include breakpoint(xs) {
        margin-top: -80px;
      }
    }
    .content {
      margin-top: 30px;
    }
  }
  .section-about {
    background-image: url("../img/bg-about.png"),
      linear-gradient(
        -179deg,
        #0a0720 9%,
        #55182c 28%,
        #54192c 69%,
        #0a081f 92%
      );
    overflow-x: hidden;
    // background-size: cover, cover;
    background-repeat: no-repeat, no-repeat;
    background-position: center;
    color: #fff;
    position: relative;
    padding-top: 216px;
    padding-bottom: 250px;
    @include breakpoint(lg) {
      padding-bottom: 200px;
    }
    @include breakpoint(md) {
      padding-bottom: 150px;
    }
    @include breakpoint(sm) {
      padding-bottom: 150px;
    }
    h1 {
      letter-spacing: 0.2px;
      font-size: 52px;
      margin-top: 2px;
      @include breakpoint(sm) {
        font-size: 36px;
      }
    }
    > .element-1 {
      position: absolute;
      z-index: 2;
      left: 50%;
      transform: translateX(-50%);
      top: 6px;
    }
    p {
      font-family: $roboto;
      line-height: 24px;
      font-size: 16px;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #ffffff;
      line-height: 1.6;
      margin: 0 23px;
      letter-spacing: 0.25px;
      font-weight: 500;
      margin-top: 0px;
      margin-right: 6px;
      letter-spacing: 0.15px;
      line-height: 25px;
      // @include breakpoint(sm) {
      //   margin: 0;
      // }
      &.a {
        @include breakpoint(xs) {
          margin: 0;
          text-align: center;
        }
      }
      &.b {
        margin-top: 23px;
        @include breakpoint(xs) {
          margin: 0;
          margin-top: 23px;
          text-align: center;
        }
      }
    }
    li {
      font-family: $roboto;
      line-height: 24px;
      font-size: 16px;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #ffffff;
      padding-bottom: 16px;
      line-height: 1.6;
      margin: 0 23px;
      letter-spacing: 0.25px;
      font-weight: 500;
      padding-left: 23px;
      margin-bottom: 6px;
      letter-spacing: 0.15px;
      line-height: 25px;
      margin-top: 7px;
      margin-bottom: 7px;
    }
    ol {
      padding-left: 46px;
      font-weight: 500;
      margin-top: 24px;
      @include breakpoint(xs) {
        padding-left: 20px;
      }
    }
  }
  .section-venue {
    padding-top: 0;
    background-image: url("../img/bg-venue.png"),
      linear-gradient(0deg, #0f1927 0%, #0b0720 16%);
    background-position: center center, center center;
    background-repeat: no-repeat, repeat;
    background-size: cover, cover;
    position: relative;
    @include breakpoint(xl) {
      padding-bottom: 0;
    }
    .information {
      @include breakpoint(xl) {
        margin-top: -22%;
      }
      @include breakpoint(lg) {
        margin-top: -22%;
      }
      @include breakpoint(md) {
        margin-top: -20%;
        margin-bottom: 30px;
      }
      @include breakpoint(sm) {
        margin-top: 30px;
      }
      @include breakpoint(xs) {
        margin-top: 30px;
      }
    }
    @include breakpoint(lg) {
      padding-bottom: 0;
    }
    .venue {
      margin-top: -86px;
      // object-fit: contain;
    }
    h3 {
      font-size: 45px;
      margin-top: -5px;
      @include breakpoint(sm) {
        font-size: 36px;
      }
      @include breakpoint(xs) {
        font-size: 23px;
      }
    }
    p {
      font-family: $roboto;
      font-size: 21px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      @include breakpoint(xl) {
        i {
          display: none;
        }
        span {
          display: inline;
        }
      }
      @include breakpoint(lg) {
        i {
          display: none;
        }
        span {
          display: inline;
        }
      }
      @include breakpoint(md) {
        i {
          display: none;
        }
        span {
          display: inline;
        }
      }
      @include breakpoint(sm) {
        font-size: 18px;
        i {
          display: none;
        }
        span {
          display: inline;
        }
      }
      @include breakpoint(xs) {
        font-size: 18px;
        i {
          display: inline;
        }
        span {
          display: none;
        }
      }
      @include breakpoint(xxs) {
        font-size: 15px;
      }
    }
    > .reference-point {
      position: relative;
      transform: translateX(-50%);
      height: 1px;
      width: 1px;
      // visibility: hidden;
      left: 50%;
      > .element-1 {
        position: absolute;
        z-index: 999;
        top: 0;
        left: 0;
        // visibility: visible;
      }
      > .element-2 {
        position: relative;
        z-index: 2;
        // display: none;
      }
    }
  }

  .section-ticket {
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    height: auto;
    background: linear-gradient(to bottom, #111929 0%, #0e1926 100%);
    // background: linear-gradient(
    //   to bottom,
    //   rgba(16, 27, 41, 1) 0%,
    //   rgba(15, 27, 42, 1) 100%
    // );
    background-size: cover;
    background-position: center;
    padding-bottom: 0;
    p {
      font-weight: 500;
    }
    @include breakpoint(xl) {
      padding-top: 0;
    }
    @include breakpoint(lg) {
      padding: 0;
    }
    @include breakpoint(md) {
      padding-top: 0;
    }
    > .element-0 {
      z-index: 0;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  #section-ticket {
    position: relative;
    display: block;
    top: -100px;
  }

  .section-contact {
    position: relative;
    display: flex;
    padding-top: 100px;
    align-items: center;
    min-height: 800px;
    background: #000 url("../img/bg-contact.jpg") no-repeat top center;
    p,
    label {
      font-family: $roboto;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: 0.2px;
      color: #ffffff;
    }
    h2 {
      font-size: 43px;
      padding-top: 170px;
      paletter-spacing: 1.3px;
      @include breakpoint(sm) {
        font-size: 36px;
      }
    }
  }

  .section-sponsors {
    padding-top: 0;
    padding-bottom: 0;
    background-color: #000000;
    background: linear-gradient(
      0deg,
      rgba(28, 32, 59, 1) 0%,
      rgba(1, 1, 1, 1) 89%
    );
    .silver {
      @include breakpoint(xxs) {
        .item-sponsor {
          flex: 0 0 50%;
          max-width: 40%;
        }
      }
    }
    a {
      transition: all 0.3s;
      &:hover {
        transform: scale(1.03);
        transition: all 0.3s;
      }
    }
    h6 {
      font-family: $roboto;
      font-size: 24px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.2px;
      color: #ffffff;
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.5;
  // width: 100%;
  // height: 100%;
  z-index: 9999;
}

#section-contact {
  position: absolute;
  top: 90px;
  display: block;
  width: 100%;
  height: 1px;
  visibility: hidden;
}
