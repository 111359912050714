* {
}

:root {
  font-size: 16px;
}

*:focus {
  outline: none !important;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1916px;
  background-color: #000;
  margin: 0 auto;
  font-family: $roboto;
}

section {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
}

h1 {
  font-family: $bebas-bold;
  text-transform: uppercase;
  font-size: 54px;
  color: #ffffff;
  letter-spacing: 0.6px;
  text-shadow: 3px 3px 0 #392296;
}

h2 {
  font-size: 43px;
  color: #ffffff;
  letter-spacing: 0.7px;
  font-family: $bebas-bold;
  text-transform: uppercase;
  position: relative;
  z-index: 2;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 2px 3px 0px #392296;
}

h3 {
  font-family: $bebas-bold;
  text-transform: uppercase;
  font-size: 36px;
  color: #ffffff;
  letter-spacing: 0.7px;
  text-shadow: 2px 3px 0px #392296;
  margin-bottom: 0;
}

p {
  color: #fff;
}

a {
  color: #fd7e14;
  &:hover {
    color: #ffae34;
    text-decoration: none;
    transition: all 100ms ease-in-out;
  }
}

#particle-js2 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: url(main.css);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.test {
  overflow-x: hidden;
}
