.shirt-container {
  display: inline-block;
  position: relative;

  > .shirt {
    margin-top: 74px;
    top: 14px;
    left: -39px;
    position: relative;
    @include breakpoint(lg) {
      position: static;
      margin-top: 30px;
      width: 100%;
      height: auto;
      margin-left: 20px;
    }
  }

  @include breakpoint(xxs) {
    span {
      display: none;
    }
  }
}
