.card-outlet {
  display: flex;
  flex-direction: row;
  margin-bottom: 60px;
  max-width: 450px;
  @include breakpoint(lg) {
    margin: 0 auto;
    margin-bottom: 40px;
    &:nth-of-type(odd) {
      // justify-content: flex-end;
    }
  }
  @include breakpoint(xs) {
    flex-direction: column;
    text-align: center;
  }
  > .image {
    align-self: center;
    margin-right: 20px;
    @include breakpoint(xs) {
      margin: 0;
      margin-bottom: 20px;
    }
  }
  > .content {
    > p {
      margin-bottom: 0;
      font-family: $roboto;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.38;
      letter-spacing: 0.2px;
      color: #ffffff;
    }
  }
}
