.title-ticket {
  margin-top: 65px;
  p {
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.7;
    letter-spacing: 0.2px;
  }
  @include breakpoint(lg) {
    margin-top: 80px;
  }
  > h3 {
    font-size: 37px;
    letter-spacing: 1.3px;
    @include breakpoint(sm) {
      font-size: 36px;
    }
  }
}
