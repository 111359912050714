.work-content {
  padding: 30px;
  height: auto;
  overflow-y: scroll;
  @include breakpoint(xl) {
    height: 600px;
    overflow: auto;
  }
  @include breakpoint(lg) {
    height: 400px;
  }
  @include breakpoint(md) {
    overflow-y: visible;
    height: auto;
  }
  > .topic {
    margin-bottom: 20px;
    margin-top: 0px;
    text-align: left;
    color: #f0447d;
    font-weight: 500;
    text-transform: capitalize;
    font-size: 21px;
    @include breakpoint(md) {
      text-align: center;
    }
  }
  > .title {
    text-align: left;
    margin-bottom: 0;
    text-transform: capitalize;
    line-height: 1;
    @include breakpoint(md) {
      text-align: center;
    }
  }
  > .social-info {
    text-align: left;
  }
  > .description {
    font-size: 15px;
    line-height: 27px;
    p:last-of-type{
      margin-bottom: 0;
    }
  }
  > .gallery {
    display: none;
    @include breakpoint(md) {
      display: flex;
    }
  }
  .images {
    margin-top: 30px;
  }
}
