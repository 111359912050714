.title-details {
  display: inline-block;
  font-family: $roboto;
  color: #ffffff;
  font-size: 28px;
  text-align: center;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 36px;
  letter-spacing: 0.1px;
  position: relative;
  z-index: 5;
  margin-top: 44px;
  @include breakpoint(sm) {
    font-size: 21px;
    line-height: 26px;
  }
  @include breakpoint(xs) {
    font-size: 15px;
    line-height: 21px;
    margin-top: 30px;
  }
  > p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  &:before {
    content: "";
    display: block;
    height: 7px;
    width: 100%;
    background-color: $grapes;
    // margin-bottom: -2px;
  }
  &:after {
    // margin-bottom: 10px;
    content: "";
    display: block;
    height: 7px;
    width: 100%;
    background-color: $grapes;
  }
}
