.modal-work {
  border-radius: 4px;
  background-color: #0b0720;
  display: flex;
  min-height: 400px;
  > .work-image {
    flex-basis: 50%;
    flex-grow: 1;
    flex-shrink: 1;
  }
  > .work-content {
    flex-basis: 50%;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
